<template>
  <v-container>
    <perseu-card title="Planos Paypal">
      <template #title-right>
        <v-btn color="secondary" @click="createPlan"> Novo Plano </v-btn>
      </template>
      <template #content>
        <!--        <filters-panel class="mb-3">-->
        <!--          <v-switch v-model="query.active" label="Ativos" />-->
        <!--          <div class="d-flex justify-end">-->
        <!--            <v-btn color="secondary" @click="findPlans">-->
        <!--              Filtrar-->
        <!--            </v-btn>-->
        <!--          </div>-->
        <!--        </filters-panel>-->
        <v-data-table :items="plans" :headers="headers">
          <template v-slot:item.actions="props">
            <table-action-button
              icon="preview"
              text="Ver detalhes"
              @click="detailPlan(props.item)"
            />
          </template>
        </v-data-table>
      </template>
    </perseu-card>
  </v-container>
</template>

<script>
import { getPlans } from "@/services/paypal-service";
import { pagesNames } from "@/router/pages-names";

export default {
  name: "Products",
  data: () => ({
    plans: [],
    headers: [
      {
        text: "ID Paypal",
        value: "idPaypal",
        width: "25%",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Nome",
        value: "name",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
        width: 200,
      },
    ],
    query: {
      active: true,
    },
  }),
  created() {
    this.findPlans();
  },
  methods: {
    async findPlans() {
      this.$store.dispatch("loading/openDialog");
      const { data } = await getPlans(this.query);
      this.plans = data;
      this.$store.dispatch("loading/closeDialog");
    },
    detailPlan(plan) {
      this.$router.push({
        name: pagesNames.PAYPAL_DETAILED_PLAN,
        query: { idPaypal: plan.idPaypal },
      });
    },
    createPlan() {
      this.$router.push({
        name: pagesNames.PAYPAL_DETAILED_PLAN,
      });
    },
  },
};
</script>

<style scoped></style>
